<template>
  <div>
    <header class="el-drawer__header nanw_khan">
      <span title="Новый заказ"
      >{{ $t("message.debts") }}</span
      >
      <el-button
          type="warning"
          icon="el-icon-close"
          size="small"
          @click="close()"
          :loading="loadingButton"
      >{{ $t("message.close") }}</el-button
      >
    </header>

    <el-card class="m-3 padding_table">
      <table class="table table-bordered table-hover" v-loading="loadingData">
        <thead>
        <tr>
          <crm-th
              :column="columns.id"
              :sort="sort"
              @c-change="updateSort"
          ></crm-th>
          <crm-th
              :column="columns.debt_type"
              :sort="sort"
              @c-change="updateSort"
          ></crm-th>
          <crm-th
              :column="columns.total_price"
              :sort="sort"
              @c-change="updateSort"
          ></crm-th>
          <crm-th
              :column="columns.paid_price"
              :sort="sort"
              @c-change="updateSort"
          ></crm-th>
          <crm-th
              :column="columns.partner_doctor"
              :sort="sort"
              @c-change="updateSort"
          ></crm-th>
          <crm-th
              :column="columns.partner_clinic"
              :sort="sort"
              @c-change="updateSort"
          ></crm-th>
          <crm-th
              :sort="sort"
              :column="columns.created_at"
              @c-change="updateSort"
          ></crm-th>
          <crm-th
              :sort="sort"
              :column="columns.settings"
              @c-change="updateSort"
          ></crm-th>
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input clearable size="mini" class="id_input" v-model="filterForm.id"
                      :placeholder="columns.id.title"></el-input>
          </th>
          <th v-if="columns.debt_type.show">
            <el-select filterable clearable :placeholder="columns.debt_type.title" size="mini" v-model="filterForm.debt_type">
              <el-option :label="$t('message.hospital')" value="hospital"></el-option>
              <el-option :label="$t('message.order')" value="order"></el-option>
            </el-select>
          </th>
          <th v-if="columns.total_price.show">
            <el-input
                size="mini"
                v-model="filterForm.total_price"
                :placeholder="columns.total_price.title"
                clearable
            ></el-input>
          </th>
          <th v-if="columns.paid_price.show">
            <el-input
                size="mini"
                v-model="filterForm.paid_price"
                :placeholder="columns.paid_price.title"
                clearable
            ></el-input>
          </th>
          <th v-if="columns.partner_doctor.show">
            <el-input
                clearable
                size="mini"
                class="d-block"
                v-model="filterForm.partner_doctor_id"
                :placeholder="$t('message.partner_clinic')"
            ></el-input>
          </th>
          <th v-if="columns.partner_clinic.show">
            <el-input
                clearable
                size="mini"
                class="d-block"
                v-model="filterForm.partner_clinic_id"
                :placeholder="$t('message.partner_clinic')"
            ></el-input>
          </th>
          <th v-if="columns.created_at.show">
            <el-date-picker :format="'dd.MM.yyyy'" :value-format="'dd.MM.yyyy'" v-model="filterForm.created_at"
                            :placeholder="columns.created_at.title" size="mini">
            </el-date-picker>
          </th>
          <th v-if="columns.settings.show"></th>
        </tr>
        </thead>

        <transition-group name="flip-list" tag="tbody">
          <tr
              v-for="(debt, index) in list"
              class="cursor-pointer"
              :key="'sss' + index"
          >
            <td v-if="columns.id.show">{{ debt.id }}</td>
            <td v-if="columns.debt_type.show">{{ $t("message." + debt.debt_type) }}</td>
            <td v-if="columns.total_price.show">
              {{ debt.total_price | formatMoney }}
            </td>
            <td v-if="columns.paid_price.show">{{ debt.paid | formatMoney }}</td>
            <td v-if="columns.partner_doctor.show">
              {{ debt.partner_doctor ? debt.partner_doctor.name : "" }}
            </td>
            <td v-if="columns.partner_clinic.show">
              {{ debt.partner_clinic ? debt.partner_clinic.name : "" }}
            </td>
            <td v-if="columns.created_at.show">{{ debt.created_at }}</td>
            <td v-if="columns.settings.show" class="settings-td">
              <template v-if="debt.debt_type === 'order'">
                <div class="d-flex">
                  <el-button round @click="show(debt)" size="mini" type="primary" icon="el-icon-view"></el-button>
                  <el-button @click="showPayment(debt.id)" icon="el-icon-sell" round></el-button>
                </div>
              </template>
              <template v-if="debt.debt_type === 'hospital'">
                <div class="d-flex">
                  <div>
                    <router-link v-can="'hospitalPatients.buttons'" :to="{ name: 'patientHome', params: { id: selected_patient.id } }">
                      <el-button size="mini" type="success" icon="el-icon-view" round></el-button>
                    </router-link>
                  </div>
                  <el-button class="ml-2" @click="pay(debt)" icon="el-icon-sell" round></el-button>
                </div>
              </template>
            </td>
          </tr>
        </transition-group>
      </table>
      <el-drawer
        :visible.sync="drawerOrderShow"
        size="80%"
        :append-to-body="true"
        :wrapperClosable="false"
        :drawer="drawerOrderShow"
      >
        <crm-show
          @c-close="closeDrawer"
          :order="selectedItem"
          :open="drawerOrderShow"
          drawer="drawerOrderShow"
        ></crm-show>
      </el-drawer>
      <el-drawer
        :visible.sync="drawerUpdate"
        size="85%"
        :append-to-body="true"
        :wrapperClosable="false"
        :drawer="drawerUpdate"
        @open="reopenUpdate = true"
        @close="reloadIfChanged('reopenUpdate')"
        @closed="emptyModel"
        class="mytab_IdP"
      >
        <div>
          <crm-update
              :reloadModel="reopenUpdate"
              @open="reopenUpdate = true"
              @c-close="closeDrawer"
          ></crm-update>
        </div>
      </el-drawer>
      <el-drawer
          size="90%"
          :wrapperClosable="false"
          :append-to-body="true"
          :visible.sync="drawer.createFrom.status"
          :ref="drawer.createFrom.name"
          :before-close="beforeClose"
          @opened="drawerOpened(drawer.createFrom.component)"
          @closed="drawerClosed(drawer.createFrom.component)"
      >
        <crm-create-from-id
            :selected="selectedModel"
            :ref="drawer.createFrom.component"
            :drawer-name="drawer.createFrom.name"
        ></crm-create-from-id>
      </el-drawer>
    </el-card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import doctors from "@/components/inventory-select/doctor_list";
import drawer from "@/utils/mixins/drawer";
import drawerChild from "@/utils/mixins/drawer-child";
import users from "@/components/inventory-select/users";
import CrmShow from "@/views/orders/components/crm-show.vue";
import CrmUpdate from "@/views/payment/components/crm-update.vue";
import CrmCreateFromId from "@/views/cashierStationary/components/crm-create-from-id.vue";
export default {
  props: ["selected_patient", "reloadModel"],
  mixins: [drawer, drawerChild],
  name: "DebtsList",
  components: {
    CrmCreateFromId,
    CrmUpdate,
    CrmShow,
    doctors,
    users,
  },
  data() {
    return {
      reloadList: false,
      drawerOrderShow: false,
      drawerExpired: false,
      drawerOrderUpdate: false,
      drawerUpdate: false,
      filterForm: {},
      reopenUpdate: false,
      excel_data: [],
      excel_fields: {},
      loadingButton: false,
      loadingData: false,
      selectedItem: null,
      count: null,
      selectedModel: {},
      drawer: {
        createFrom: {
          name: "createFrom",
          component: "componentCreateFrom",
          status: false,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      list: "debts/list",
      doctor_list: "users/list",
      columns: "debts/columns",
      filter: "debts/filter",
      pagination: "debts/pagination",
      sort: "debts/sort",
      model: "debts/model",
      users: "users/inventory_general",
    }),
    actions: function () {
      return ["edit", "delete", "show"];
    },
  },
  async mounted() {
    const query = { can: "orders.create" };
    await this.getUsers();
  },
  watch: {
    async reloadModel(newVal, oldVal) {
      await this.fetchData();
    },
    async filterForm(newVal, oldVal) {
      await this.updateFilter(newVal);
    },
    async sort(newVal, oldVal) {
      await this.fetchData();
    },
    columns() {
      this.controlExcelData();
    }
  },
  methods: {
    ...mapActions({
      updateList: "debts/index",
      doctorList: "users/index",
      updateSort: "debts/updateSort",
      updateFilter: "debts/updateFilter",
      updateColumn: "debts/updateColumn",
      updatePagination: "debts/updatePagination",
      empty: "debts/empty",
      refreshData: "debts/refreshData",
      getUsers: "users/inventory_general",
      showModel: "payment/relationShow",
    }),
    async fetchData() {
      this.filter.selected_patient = this.selected_patient.id;
      const query = { ...this.filter, ...this.sort};
        this.loadingData = true;
        await this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });

    },
    listChanged() {
      this.reloadList = true;
    },
    closeDrawer(drawer) {
      if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
        this.$refs[drawer].closeDrawer()
      }
    },
    drawerClosed(ref) {
      if (this.$refs[ref]) {
        this.$refs[ref].closed()
      }
      if (this.reloadList === true) {
        this.fetchData()
      }
      if (_.isFunction(this.empty)) {
        this.empty()
      }
    },
    drawerOpened(ref) {
      if (this.$refs[ref]) {
        if (_.isFunction(this.$refs[ref].opened)) {
          this.$refs[ref].opened()
        }
      }
    },
    show(model) {
      this.selectedItem = model;
      this.drawerOrderShow = true;
    },
    async showPayment(id) {
      const query = { id: id, relation: true };
      this.showModel(query)
          .then((res) => {
            this.drawerUpdate = true;
          })
          .catch((err) => {});
    },
    pay(model) {
      this.selectedModel = model;
      this.drawer.createFrom.status = true;
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
          this.fetchData();
        })
        .catch((err) => {});
    },
    afterOpened() {
      this.fetchData();

      this.form = JSON.parse(JSON.stringify(this.model));
    },
    afterClosed() {
      this.empty();
    },

    emptyModel() {
      this.empty();
    },
    beforeClose(done) {
      this.empty();
      this.refreshData();
      done();
    },
  },
};
</script>
